<template lang="pug">
div.player-message-container
  div.info-region
    //- 消息发送及列表（PC端）
    comp-im(v-if="!$isMobile" ref="imMessage" v-show="isIMTabActive")
    //- 消息发送及列表（移动端）
    comp-im-mobile(
      v-if="$isMobile"
      ref="imMessage"
      v-show="isIMTabActive"
      :isMobileVerticalLayout="isMobileVerticalLayout"
      :isMobileHorizontalLayout="isMobileHorizontalLayout")


</template>

<script>
import compIm from './comp-im-pc';
import compImMobile from './comp-im-mobile';
import compInfo from './comp-info.vue';
export default {
  name: 'compMessage',
  props: {
    isMobileVerticalLayout: {
      type: Boolean,
    },
    isMobileHorizontalLayout: {
      type: Boolean,
    },
  },
  data() {
    return {
      activeTab: 'im',
      hideIntroductionComp: false,
    };
  },
  computed: {
    isIMTabActive() {
      return this.activeTab === 'im' || this.isMobileHorizontalLayout;
    },
    isIntroductionActive() {
      return !this.isMobileHorizontalLayout && this.activeTab === 'introduction';
    },
  },
  components: {
    compIm,
    compImMobile,
    compInfo,
  },
  methods: {
    changeActiveTab(tabName) {
      this.activeTab = tabName;
    },
    // 处理用户退出直播间
    handleExit() {
      this.$refs.imMessage.quitGroup();
    },
  },
};
</script>

<style lang="stylus" scoped>
  .player-message-container
    width 100%
    height 100%
    display flex
    flex-direction column
    .info-region
      width 100%
      height 150px
      flex-grow 1
      overflow-y auto
      overflow-x hidden
      position:fixed;
      bottom:40px;
      left: 0px
</style>

<i18n>
{
	"en": {
		"Chat": "Chat",
		"Introduction": "Introduction"
	},
	"zh": {
		"Chat": "讨论区",
		"Introduction": "直播介绍"
	}
}
</i18n>
